"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useAnalyticsContentCategory = contentCategory => {
  (0, _react.useEffect)(() => {
    if (!contentCategory) {
      return;
    }
    setTimeout(() => {
      window.dataLayer = window.dataLayer ?? [];
      window.dataLayer.push({
        content_category: contentCategory
      });
    });
  }, [contentCategory]);
};
var _default = exports.default = useAnalyticsContentCategory;