"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchViewParams = exports.searchFilterParams = exports.searchBarParams = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _useQueryParams = require("use-query-params");
var _ArrayParam = _interopRequireDefault(require("../utils/ArrayParam"));
var _NumberParam = _interopRequireDefault(require("../utils/NumberParam"));
var _bookingParams = _interopRequireDefault(require("./bookingParams"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const searchFilterParams = exports.searchFilterParams = {
  andFilters: _ArrayParam.default,
  orFilters: _ArrayParam.default,
  tripType: _ArrayParam.default,
  specialId: (0, _useQueryParams.withDefault)(_useQueryParams.StringParam, undefined),
  minPrice: _NumberParam.default,
  maxPrice: _NumberParam.default,
  minimalMinPrice: _NumberParam.default,
  maximalMaxPrice: _NumberParam.default
};
const searchViewParams = exports.searchViewParams = {
  offset: (0, _useQueryParams.withDefault)(_NumberParam.default, undefined),
  order: _useQueryParams.StringParam,
  bbTop: _NumberParam.default,
  bbBottom: _NumberParam.default,
  bbLeft: _NumberParam.default,
  bbRight: _NumberParam.default
};
const searchBarParams = exports.searchBarParams = _objectSpread(_objectSpread({}, _bookingParams.default), {}, {
  accommodationType: (0, _useQueryParams.withDefault)(_useQueryParams.StringParam, undefined, true),
  period: _useQueryParams.StringParam
});
const searchParams = _objectSpread(_objectSpread(_objectSpread({}, searchFilterParams), searchViewParams), searchBarParams);
var _default = exports.default = searchParams;