"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orFilterRegExp = exports.mergeParams = exports.matchHandle = exports.getAccommodationFilters = exports.convertToQueryFilters = exports.convertToOrFilters = exports.convertToFormikFilters = exports.convertToFilters = exports.convertToAndFilters = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _lodash = _interopRequireDefault(require("lodash.mergewith"));
var _lodash2 = _interopRequireDefault(require("lodash.omit"));
var _searchParams = require("../constants/searchParams");
var _getDateParamsForPeriodType = _interopRequireDefault(require("./getDateParamsForPeriodType"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** convert the filters object ```{ someFilter: true }``` to ```['someFilter']``` */
const convertToQueryFilters = filters => filters && Object.keys(filters);

/** convert the filters ```['someFilter', 'anotherFilter']``` to the formik-ready ```{ someFilter: true, anotherFilter: true }``` */
exports.convertToQueryFilters = convertToQueryFilters;
const convertToFormikFilters = filters => filters.reduce((acc, next) => _objectSpread(_objectSpread({}, acc), {}, {
  [next]: true
}), {});
exports.convertToFormikFilters = convertToFormikFilters;
const orFilterRegExp = exports.orFilterRegExp = /_(.*)\./;
const matchHandle = property => orFilterRegExp.exec(property)?.[0];

/**  AND filters need to be formatted like: [{ properties: ['filterA'] }, { properties: ['filterB'] }] */
exports.matchHandle = matchHandle;
const convertToAndFilters = filters => filters.map(filter => ({
  properties: [filter]
}));

/**  OR filters need to be formatted like: [{ properties: ['filterA', 'filterB', 'filterC'] }] */
exports.convertToAndFilters = convertToAndFilters;
const convertToOrFilters = filters => filters.reduce((acc, next) => {
  // first check if there's already an array of certain OR filters
  const orFilterIndex = acc.findIndex(({
    properties
  }) => {
    const [property] = properties;
    const handle = matchHandle(property);
    const nextHandle = matchHandle(next);
    return handle === nextHandle;
  });

  // when we have a match we want to extend the current properties for that OR filter
  if (orFilterIndex !== -1) {
    acc[orFilterIndex] = {
      properties: [...acc[orFilterIndex].properties, next]
    };
    return acc;
  }
  return [...acc, {
    properties: [next]
  }];
}, []);
exports.convertToOrFilters = convertToOrFilters;
const getAccommodationFilters = type => type.length ? type.split(' ').filter(value => value !== 'all').map(value => value) : [];
exports.getAccommodationFilters = getAccommodationFilters;
const convertToFilters = (...args) => args.reduce((acc, [filters, type]) => {
  if (type === 'OR') {
    return [...acc, ...convertToOrFilters(filters)];
  }
  return [...acc, ...convertToAndFilters(filters)];
}, []);
exports.convertToFilters = convertToFilters;
const customizer = (obj, src) => {
  if (Array.isArray(obj) && Array.isArray(src)) {
    return [...new Set(obj.concat(src))];
  }
  return undefined;
};
const mergeParams = (startParams, initialStorageVals, params, bakedInParams, bakedInFilterProperty) => {
  const paramsActive = Object.keys((0, _lodash2.default)(params, Object.keys(_searchParams.searchViewParams))).length > 0;
  return (0, _lodash.default)({},
  // only take into account the startParams if there aren't any relevant params set
  paramsActive ? {} : startParams, _objectSpread(_objectSpread(_objectSpread({}, initialStorageVals?.booking), initialStorageVals?.accommodationType && {
    accommodationType: initialStorageVals?.accommodationType
  }), (0, _getDateParamsForPeriodType.default)(startParams?.period ?? params?.period ?? bakedInParams?.period)), params, bakedInParams, bakedInFilterProperty ? {
    andFilters: [bakedInFilterProperty]
  } : {}, customizer);
};
exports.mergeParams = mergeParams;