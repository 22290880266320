"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useQueryParams = require("use-query-params");
var _DateParam = _interopRequireDefault(require("../utils/DateParam"));
var _NumberParam = _interopRequireDefault(require("../utils/NumberParam"));
const bookingParams = {
  type: (0, _useQueryParams.withDefault)((0, _useQueryParams.createEnumParam)(['static', 'flexible-random', 'flexible-weekends', 'coming-month']), undefined),
  amountOfNights: (0, _useQueryParams.withDefault)(_NumberParam.default, undefined),
  flexibleMonth: (0, _useQueryParams.withDefault)(_useQueryParams.StringParam, undefined),
  arrivalDate: _DateParam.default,
  departureDate: _DateParam.default,
  amountAdults: (0, _useQueryParams.withDefault)(_NumberParam.default, undefined),
  amountChildren: (0, _useQueryParams.withDefault)(_NumberParam.default, undefined),
  amountPets: (0, _useQueryParams.withDefault)(_NumberParam.default, undefined),
  amountBabies: (0, _useQueryParams.withDefault)(_NumberParam.default, undefined),
  amountYouths: (0, _useQueryParams.withDefault)(_NumberParam.default, undefined)
};
var _default = exports.default = bookingParams;