"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
const UspBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UspBody",
  componentId: "bd2b1e-0"
})(["font-weight:500;color:", ";display:flex;align-items:center;svg{stroke-width:3px;width:23px;height:23px;margin-right:", ";}"], ({
  theme
}) => theme.colors.secondary[50], ({
  theme
}) => theme.spacing['30_Small']);
var _default = exports.default = UspBody;