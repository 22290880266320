"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _getDay = _interopRequireDefault(require("date-fns/getDay"));
var _addWeeks = _interopRequireDefault(require("date-fns/addWeeks"));
const getUpcomingDay = (day, weekMargin = 0) => {
  const currentDate = (0, _addWeeks.default)(new Date(), weekMargin);
  const current = (0, _getDay.default)(currentDate);
  const diff = day - current;
  return (0, _addDays.default)(currentDate, diff === -1 ? 6 : diff); // adjust when the currentDate is saturday
};
var _default = exports.default = getUpcomingDay;