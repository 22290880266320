"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _TenantContext = require("../context/TenantContext");
var _Accordion = _interopRequireDefault(require("./designsystem/Accordion"));
var _AccordionItem = _interopRequireWildcard(require("./designsystem/AccordionItem"));
var _UspBody = _interopRequireDefault(require("./UspBody"));
const _excluded = ["visibleIndex"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const UspsModal = _ref => {
  let {
      visibleIndex
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const usps = brandConfig.usps ?? [];
  if (!usps.length) return null;
  return __jsx(_Modal.default, (0, _extends2.default)({
    variant: "regular"
  }, props), __jsx(_ModalHeader.default, null, __jsx(_reactIntl.FormattedMessage, {
    values: {
      count: usps.length
    },
    id: "lyuDhN",
    defaultMessage: [{
      "type": 1,
      "value": "count"
    }, {
      "type": 0,
      "value": " redenen om bij ons te boeken!"
    }]
  })), __jsx(_Accordion.default, {
    defaultIndex: visibleIndex,
    mt: 4
  }, usps.map(({
    title,
    description
  }, index) => __jsx(_AccordionItem.default, {
    key: index,
    index: index
  }, __jsx(_AccordionItem.AccordionItemHeader, null, __jsx(_UspBody.default, {
    variant: "large"
  }, title)), __jsx(_AccordionItem.AccordionItemContent, {
    pl: 5,
    py: 3
  }, __jsx(_Body.default, null, description))))));
};
var _default = exports.default = UspsModal;