"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _addWeeks = _interopRequireDefault(require("date-fns/addWeeks"));
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _getUpcomingDay = _interopRequireDefault(require("./getUpcomingDay"));
const getDateParamsForPeriodType = periodType => {
  const nextFriday = (0, _getUpcomingDay.default)(5, 1);
  switch (periodType) {
    case 'nextWeek':
      {
        return {
          arrivalDate: (0, _parseToDateString.default)(nextFriday),
          departureDate: (0, _parseToDateString.default)((0, _addWeeks.default)(nextFriday, 1))
        };
      }
    case 'nextWeekend':
      {
        return {
          arrivalDate: (0, _parseToDateString.default)(nextFriday),
          departureDate: (0, _parseToDateString.default)((0, _addDays.default)(nextFriday, 2))
        };
      }
    case 'comingMonth':
      {
        return {
          arrivalDate: undefined,
          departureDate: undefined
        };
      }
    default:
      return undefined;
  }
};
var _default = exports.default = getDateParamsForPeriodType;